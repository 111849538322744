import React, { Component } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import pdfDoc from "../../files/lastMain.pdf";
import "./styles.scss";
import personalData from "../../files/Политика за защита на личните данни.pdf";
import clearingData from "../../files/Политика за съхранение и унищоване на личните данни.pdf";
import thirdDocument from "../../files/Политика относно упражняване на правата на субектите на данни.pdf";
import fourthDocument from "../../files/GDPR014-candidates.docx";
import fifthDocument from "../../files/GDPR013-clients.doc";
import sixthDocument from "../../files/Uprajnenie_na_prava.odt"
import cookies from "../../files/cookies-policy(4).pdf";
import TOS from "../../files/tos(5).pdf";
import protocol from "../../files/const-prot(1).pdf";
import protocolManual from "../../files/const-prot-manual.pdf";
import newFile from "../../files/325.pdf";
import newSectionFile1 from "../../files/podavane_signal_narushenie_2.pdf";
import newSectionFile2 from "../../files/registirane_na_narushenie.pdf";

const offDocs = [
  // {
  //   name: "Политика за защита на личните данни",
  //   url: personalData,
  // },
  // {
  //   name: "Политика за съхранение и унищожаване на лични данни",
  //   url: clearingData,
  // },
  // {
  //   name: "Политика относно упражняване на правата на субектите на данни",
  //   url: thirdDocument,
  // },
  {
    name: "Уведомление за Поверителност за кандидати за Работа",
    url: fourthDocument,
  },
  {
    name: "Уведомление за Поверителност за Клиентите",
    url: fifthDocument,
  },
  {
    name: "Заявление за упражняване на права по чл.15-22 от Регламент (ЕС) 2016/679",
    url: sixthDocument,
  },
];

const mainDocs = [  
  {
    name: "Политика за бисквитките",
    url: cookies,
  },
  {
    name: "Лиценз",
    url: "https://amarant2.s3.eu-central-1.amazonaws.com/app/amarantlicense.pdf",
  },
  {
    name: "Общи условия за ползване",
    url: TOS,
  },
  {
    name: "Информация по чл.325 от КЗ",
    url: newFile,
  },
  {
    name: "Двустранен констативен протокол",
    url: protocol,
  },
  {
    name: "Указания за попълване на Двустранен констативен протокол",
    url: protocolManual,
  },
  {
    name: "Политика за жалбите",
    url: pdfDoc,
  },
];

const thirdSection = [
  {
    name: "Подаване на сигнали за нарушение",
    url: newSectionFile1,
  },
  {
    name: "Формуляр за регистриране на сигнал",
    url: newSectionFile2,
  },
];

const Documents = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="documents">
      <div className="documents-container">
        <div className="left-documents-container" style={{ width: "29%" }}>
          <div className="main-documents">ОСНОВНИ ДОКУМЕНТИ</div>
          <div className="documents-list">
            {mainDocs.map((doc) => {
              return (
                <div
                  className="doc-container"
                  onClick={() => {
                    window.open(doc.url);
                  }}
                >
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue"></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="left-documents-container" style={{ marginLeft: "4%", width: "29%" }}>
          <div className="main-documents">GDPR ДОКУМЕНТИ</div>
          <div className="documents-list">
            {offDocs.map((doc) => {
              return (
                <div
                  className="doc-container"
                  onClick={() => {
                    window.open(doc.url);
                  }}
                >
                  <h4 className="doc-name">{doc.name}</h4>
                  <div
                    className="arrowTab selectedBlue"
                    onClick={() => {
                      window.open(doc.url);
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="left-documents-container" style={{ marginLeft: "4%", width: "29%" }}>
          <div className="main-documents">ЗЗЛПСПОИН</div>
          <div className="documents-list">
            {thirdSection.map((doc) => {
              return (
                <div className="doc-container" onClick={() => window.open(doc.url)}>
                  <h4 className="doc-name">{doc.name}</h4>
                  <div className="arrowTab selectedBlue" onClick={() => window.open(doc.url)}></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Documents);
